<template>
  <div></div>
</template>

<script>
import routeTypes from '@/router/routeTypes';
import { mixin as mixinHelperUtils, mixinAuth } from '@/common/helperUtils';

export default {
  name: 'SignOut',
  mixins: [mixinHelperUtils, mixinAuth],
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  mounted() {
    if (this.$store.state.userInfo) {
      this.signOut(); // mixinAuth
      this.showAlert(this.$t('alertMessage.logout'), () => {
        this.$router.replace({
          name: routeTypes.ROUTE_NAME_SIGNIN,
        });
      });
    } else {
      this.$router.replace({
        name: routeTypes.ROUTE_NAME_SIGNIN,
      });
    }
  },
};
</script>
